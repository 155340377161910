<template>
  <div id="Manage">
    <!-- 基础设置 卡券管理-->

    <!-- Dialog 对话框 -->
    <el-dialog :title="dialogFonfig.openType === 'add' ? dialogFonfig.addName : dialogFonfig.editName"
               :visible.sync="dialogFonfig.isShowDialogVisible"
               @close="clickCancelData">
      <CommonForm :form="openForm"
                  :formLabel="openFormLabel"
                  ref="form"></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="primary"
                   @click="clickSubmitData(dialogFonfig.openType, openForm)">确 定</el-button>
      </div>
    </el-dialog>

    <CommonManageMain>
      <template v-slot:header>
        <div>
          <el-button type="primary"
                     size="small"
                     @click="clickAddData"
                     icon="el-icon-circle-plus-outline">添加卡券类型</el-button>
          <!-- <el-button type="danger" size="small" @click="clickDelData" icon="el-icon-delete">删除</el-button> -->

          <CommonForm :form="searchForm"
                      :formLabel="searchformLabel"
                      class="filtrateInput">
            <el-button type="primary"
                       @click="searchCard(searchForm)">搜索</el-button>
            <el-button>导出</el-button>
            <el-button type="text">查看已导出列表</el-button>
            <el-button type="text"
                       @click="resetFilter(searchForm)">重置筛选条件</el-button>
            <div class="line">—</div>
          </CommonForm>
        </div>
      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTable :tableData="tableData"
                     :tableLabel="tableLabel"
                     :tableConfig="tableConfig"
                     @getPage="getPage">
          <el-table-column label="操作"
                           width="180">
            <template slot-scope="scope">
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="编辑"
                          placement="top">
                <span @click="clickEditData(scope.$index, scope.row)">编辑</span>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="删除"
                          placement="top">
                <span @click='clickDeleteData(scope.$index, scope.row)'>删除</span>
              </el-tooltip>

            </template>
          </el-table-column>
        </CommonTable>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import {
  getCardList, metCardList
}
  from 'api/basis.js'
import {
  getAreaFirst,
  // getAreaCode
}
  from 'api/basisDate.js'
import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'

export default {
  components: {
    CommonForm,
    CommonTable,
    CommonManageMain
  },
  data () {
    return {
      areaName: [],
      areaID: [],
      searchData: '',//存放搜索的数据，分页时使用
      // 对话框 Dialog 基础数据
      dialogFonfig: {
        openType: 'add',
        addName: '新增卡券类型信息',
        editName: '编辑卡券类型信息',
        isShowDialogVisible: false
      },

      // form表单 基础数据
      openForm: {
        id: '',
        level: '',
        name: '',
        total: '',
        worth: '',
        available_times: '',
        stock_count: '',
        valid_months: '',
        limit_num: '',
        return_way: '',
      },
      openFormLabel: [
        {
          model: 'level',
          label: '卡等级',
        },
        {
          model: 'name',
          label: '卡类型名称',
        },
        {
          model: 'total',
          label: '卡总数',
        },
        {
          model: 'worth',
          label: '卡面值',
        },
        {
          model: 'available_times',
          label: '可用次数',
        },
        {
          model: 'stock_count',
          label: '剩余库存',
        },
        {
          model: 'valid_months',
          label: '激活后有效期',
        },
        {
          model: 'limit_num',
          label: '限制数量',
        },
        {
          model: 'return_way',
          label: '推荐奖',
          type: 'select',
          options: [
            { value: 0, label: '抵用金' },
            { value: 1, label: '余额' },
          ]
        },
      ],

      // form表单 搜索数据
      searchForm: {
        id: '',
        name: '',
        minArea: '',
        maxArea: ''
      },
      searchformLabel: [
        {
          model: 'id',
          label: '卡类型ID',
          width: '300px'
        },
        {
          model: 'name',
          label: '卡类型名称',
          width: '300px'
        },
        {
          model: 'minArea',
          label: '等级范围',
          width2: '80px'
        },
        {
          model: 'maxArea',
          label: '',
          width2: '80px',
          class: 'range'
        },
      ],
      // table 表格 基础数据
      tableData: [],
      tableLabel: [
        {
          prop: 'id',
          label: '卡ID',
        },
        {
          prop: 'level',
          label: '等级'
        },
        {
          prop: 'name',
          label: '名称'
        },
        {
          prop: 'worth',
          label: '面值'
        },
        {
          prop: 'available_times',
          label: '可用次数'
        },
        {
          prop: 'stock_count',
          label: '库存'
        },
        {
          prop: 'valid_months',
          label: '激活有效期'
        },
        {
          prop: 'total',
          label: '数量'
        },
        {
          prop: 'return_way',
          label: '推荐/分享奖'
        },
        {
          prop: 'update_time',
          label: '更新时间'
        }
      ],
      tableConfig: {
        isShowLoading: true,
        // 改造状态列显示效果 el-switch

        page: 1,
        total: 30,
        // 加载状态
        loading: false,
        // 显示 选择多行数据
        selection: false
      },


      // 景点-全部-数据
      cardAlldataObj: {},

      // id最大值
      MaxId: ''
    }
  },

  mounted () {
    this.getCardList();

  },
  beforeUpdate () {
  },
  watch: {
    cardAlldataObj: function () {
      this.tableData = this.dataFormatDeal(
        this.cardAlldataObj.list,
        {
          return_way: 'return_way'
        },

      )
      this.handleData()
    }
  },

  methods: {

    //获得下列分类页的页数
    getPage () {
      // this.getCardList()
      if (this.searchData) {
        this.searchData.page = this.tableConfig.page
        this.getCardList(this.searchData)
      } else {
        this.getCardList()
      }
    },
    // 获取--卡券列表数据
    getCardList (data) {
      if (data) {
        getCardList(data).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.cardAlldataObj = DATA.data
            this.tableConfig.total = DATA.data.total
          }
        })
      } else {
        getCardList({
          page: this.tableConfig.page,
          page_rows: 10
        }).then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.cardAlldataObj = DATA.data

            this.tableConfig.total = DATA.data.total
            this.tableConfig.loading = false
          }

        })
      }



    },
    // 处理数据
    handleData () {
      this.tableData.forEach((item) => {
        item.worth = '￥' + item.worth
        item.valid_months = item.valid_months + '个月'
        item.total = item.total + '张'
        if (item.return_way == 0) {
          item.return_way = '抵用金'
        } else {
          item.return_way = '余额'
        }
      })
    },

    //添加数据   --添加-编辑 景点
    metCardList (met, data) {
      return new Promise(resolve => {
        metCardList(met, data).then(res => {
          console.log(res)
          const DATA = res.data
          resolve(DATA)
          console.log(DATA)
        })
      })

    },

    // 数据格式处理
    dataFormatDeal (arr, keyMap, copyInKeyMap) {
      // global 全局方法处理

      return this.globalDeepCloneData(arr, keyMap, copyInKeyMap)
    },

    // 获取最大的 id值
    MaxID (arr, keyMap) {
      let maxid = 0
      for (const val of arr) {
        maxid = maxid > val[keyMap] ? maxid : val[keyMap]
      }
      return maxid
    },

    // 添加数据
    clickAddData () {
      this.MaxId = this.MaxID(this.tableData, 'id')
      this.openForm.id = this.MaxId + 1
      this.dialogFonfig.isShowDialogVisible = true

      console.log(this.cardAlldataObj.list)

    },

    // 确定提交数据操作
    clickSubmitData (type, data) {
      console.log(data)

      if (type == 'add') {
        const DATA = {
          level: data.level,
          name: data.name,
          total: data.total,
          worth: data.worth,
          available_times: data.available_times,
          stock_count: data.stock_count,
          valid_months: data.valid_months,
          limit_num: data.limit_num,
          return_way: data.return_way,

        }
        // console.log(DATA)
        this.metCardList('POST', DATA)
        this.getCardList()

      }
      else if (type == 'edit') {
        const DATA = {
          id: data.id,
          level: data.level,
          name: data.name,
          total: data.total,
          worth: data.worth,
          available_times: data.available_times,
          stock_count: data.stock_count,
          valid_months: data.valid_months,
          limit_num: data.limit_num,
          return_way: data.return_way,
        }
        this.metCardList('PUT', DATA)
        this.getCardList()
      }
      this.updataTableData()
      this.dialogFonfig.isShowDialogVisible = false
      this.openForm = this.$options.data().openForm;
    },
    // 取消提交数据操作
    clickCancelData () {
      this.dialogFonfig.isShowDialogVisible = false
      // 用于当前 Vue 实例的初始化选项
      this.openForm = this.$options.data().openForm
    },

    // 编辑操作
    async clickEditData (idx, row) {
      this.openForm = {
        id: row.id,
        level: row.level,
        name: row.name,
        total: row.total,
        worth: row.worth,
        available_times: row.available_times,
        stock_count: row.stock_count,
        valid_months: row.valid_months,
        limit_num: row.limit_num,
        return_way: row.return_way,
      }
      console.log(this.openForm.return_way)
      if (this.openForm.return_way == '抵用金') {
        this.openForm.return_way = 0
      } else if (this.openForm.return_way == '余额') {
        this.openForm.return_way = 1
      }
      this.openForm.worth = this.openForm.worth.substr(1)
      this.openForm.total = Number(this.openForm.total.substring(0, this.openForm.total.length - 1))
      this.openForm.valid_months = (this.openForm.valid_months.substring(0, this.openForm.valid_months.length - 1))
      this.openForm.valid_months = Number(this.openForm.valid_months.substring(0, this.openForm.valid_months.length - 1))
      // console.log(row.return_way)
      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'edit'
    },
    // 删除操作
    async clickDeleteData (idx, row) {
      const DATA = {
        id: row.id
      }
      console.log(row.id)
      await this.metCardList('DELETE', DATA)
      await this.updataTableData()
    },
    //刷新表格
    updataTableData () {
      this.getCardList()
    },
    //搜索卡券
    searchCard (searchForm) {
      console.log(searchForm)
      if (searchForm.minArea === '') {
        searchForm.minArea = '1'
        searchForm.maxArea = '1000'
      }
      const data = {
        id: searchForm.id,
        name: searchForm.name,
        level: [searchForm.minArea, searchForm.maxArea]
      }
      if (searchForm.maxArea === '1000') {
        searchForm.minArea = ''
        searchForm.maxArea = ''
      }
      this.searchData = data
      console.log(data.level)
      this.getCardList(data)
    },
    //重置筛选条件
    resetFilter (searchForm) {
      searchForm.id = '';
      searchForm.name = '';
      searchForm.minArea = ''
      searchForm.maxArea = ''
      this.searchData = ""
      this.getCardList()
    },
  }
}
</script>

<style lang="scss" scoped>
#Manage {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
  .el-transfer {
    text-align: center;
    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }
  .filtrateInput {
    background: #f8f7f7;
    width: 69%;
    padding: 12px 8px;
    padding-right: 45%;
    margin: 8px 0px;
    position: relative;
    .line {
      position: absolute;
      top: -49px;
      left: 692px;
      color: #d8d8d8;
    }
  }
  span {
    font-size: 14 px;
    margin-left: 8px;
    color: #0486fe;
    cursor: pointer;
  }
  /deep/ .el-dialog {
    width: 30%;
  }
}
</style>
